import React from "react"
import ChevronRight from "../icons/chevronRight"
import Pencil from "../icons/pencil"
import Spanner from "../icons/spanner"
import OfficeIcon from '../icons/officeIcon'

const Services = () => {
  return (
    <div className="hide-lg d-flex column align-items-center">
      <p className="my-20 font-size-20">Services</p>
      <div className="d-flex row">
        <a
          href={`/services`}
          className="mt-150-lg text-info my-20 mr-20 letter-spacing"
        >
          Learn more
        </a>
        <ChevronRight fill="blue" />
      </div>
      <div className="d-flex row justify-content-around w-100 mb-50 mt-50">
        <div className="d-flex column align-items-center">
          <Pencil />
          <p className="font-size-14 text-secondary">Design</p>
        </div>
        <div className="d-flex column align-items-center text-align-center-sm">
          <Spanner />
          <p className="font-size-14 text-secondary">
            Engineering & <br /> mold design
          </p>
        </div>
        <div className="d-flex column align-items-center">
          <OfficeIcon />
          <p className="font-size-14 text-secondary">Manufacturing</p>
        </div>
      </div>
    </div>
  )
}

export default Services
