import React, { useState, useEffect } from 'react';
import { PRODUCTS } from '../../../env';


const MoreProducts = () => {
  useEffect(() => {
    setProducts(getPageProducts(1))
  }, [])
  const [products, setProducts] = useState([])
  const getPageProducts = (currentPage) => {
    const start = ((currentPage - 1) ) % 3
    return Object.entries(PRODUCTS).map(([key, value]) => value).slice(start, start + 3)
  }
  return (
    <div className="container d-flex column align-items-center hide-sm">
      <h3 className="my-50 font-size-25 font-bold">PRODUCTS</h3>
      <div className="d-flex row justify-content-around align-items-center w-100 overflow-auto">
        {products.map((product, index) => {
          return (
            <div key={index} className="" style={{ width: '23vw', height: '30vw', padding: 10}}>
              <div className="mb-50" style={{position: 'relative', height: '14vw', backgroundColor: '#F7F7F7'}}>
                <img src={product.display_image} alt="product display" style={{ width: '100%', position: 'absolute'}}/>
              </div>
              <a href={`/products/${product.slug}`} className="mt-50 font-size-25 font-bold cursor-pointer">{product.name}</a>
              <p className="font-size-14 text-secondary">{product.main_description}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MoreProducts;