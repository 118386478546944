import React, { useEffect, useState } from "react"

import Layout from "components/layout"
import SEO from "components/seo"
import Clients from "components/banners/clients"
import MoreProducts from "components/products/moreProducts"
import Button from "components/ui/button"
import Services from "components/banners/services"
import { PRODUCTS } from "../../env"
import Product from "components/products/product"

const IndexPage = () => {
  const [animationName, setAnimationName] = useState("animateFadeInRight")
  const [carouselIndex, setCarouselIndex] = useState(0)
  let temp = 0
  const carouselImages = [
    "/home/image_1.png",
    "/home/image_2.png",
    "/home/image_3.png",
  ]
  useEffect(() => {
    initiateCarousel()
  }, [])
  function initiateCarousel() {
    setTimeout(() => {
      setAnimationName("animateFadeOutRight")
    }, 4000)
    setTimeout(() => {
      temp += 1
      setCarouselIndex(carouselIndex + temp)
    }, 5000)
    setTimeout(() => {
      setAnimationName("animateFadeInRight")
      initiateCarousel()
    }, 5500)
  }
  return (
    <Layout path="/">
      <SEO
        title="Home"
        description="Home Page Description"
        keywords={[
          `Rishaba`,
          `Butterfly`,
          `Home Appliances`,
          `Industrial Design`,
          `Engineering`,
          `Mold Design`,
          `Manufacturing`,
        ]}
      />
      <div className="page-gradient d-flex h-500-lg">
        <div className="container w-50-lg d-flex column mt-150-lg ">
          <div className="d-flex justify-content-around">
            <div className="vl-black hide-sm"></div>
            <div className="mt-50-sm ml-20-sm">
              <h1 className="font-size-66 font-size-25-sm m-0 font-bold">
                Rishaba
              </h1>
              <p className="text-secondary">
                Your one stop solution for design, engineering and manufacturing
              </p>
            </div>
          </div>
        </div>
        <div className="w-50-lg d-flex justify-content-center">
          <img
            id="homepageImage"
            className={`w-100 ${animationName}`}
            src={carouselImages[carouselIndex % 3]}
            alt="homepage product"
          />
        </div>
      </div>
      <Services />
      <div className="hide-lg">
        {Object.keys(PRODUCTS).slice(0, 3).map((product, index) => {
          return (
            <Product index={index} product={product} key={index}/>
          )
        })}
      </div>
      <div className="hide-sm">
        <MoreProducts />
      </div>
      <Button name="Explore more" link="/products" orientation="center" className="mt-50-sm mb-50" />
      {/* <Clients /> */}
    </Layout>
  )
}

export default IndexPage
