import React from "react"

const Spanner = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 417.225 417.225"
      width="20pt"
    >
      <path d="M404.237,67.346c-4.388-6.005-13.369-2.019-12.263,4.287c-0.714,0.044-1.443,0.153-2.187,0.553  c-9.144,4.9-13.056,18.16-18.232,26.81c-6.473,10.815-13.918,20.98-20.592,31.658c-0.313,0.501-0.457,1.006-0.574,1.504  c-6.698,0.543-13.424,2.274-19.973,3.485c-5.78,1.07-11.571,2.13-17.34,3.29c-3.234-5.622-9.181-9.235-14.093-13.487  c-4.317-3.734-8.332-7.781-12.43-11.745c0.295-0.291,0.707-0.437,0.916-0.818c3.656-6.644,3.281-14.696,3.388-22.155  c0.087-6.095-0.155-12.161-0.691-18.214c12.077-6.104,22.395-17.176,33.306-24.915c10.78-7.644,21.93-14.189,27.836-26.487  c1.214-2.527-0.575-4.741-2.759-4.836c-0.102-0.556-0.26-1.112-0.713-1.527c-9.421-8.67-20.113-13.046-31.184-14.339  c-0.918-0.274-1.899-0.322-2.91-0.274c-29.017-1.936-60.164,17.15-78.106,39.931c-23.516,29.86-30.862,68.871-17.529,102.821  C158.185,192.363,104.215,249.86,45.171,300.652c-30.33,26.091-61.33,62.413-33.404,101.338c0.946,1.316,2.515,1.853,4.004,1.763  c16.4,20.012,50.171,15.748,74.148,0.141c30.57-19.9,57.182-54.095,82.28-80.345c37.251-38.957,73.306-79.023,108.77-119.609  c0.97-1.112,1.399-2.3,1.613-3.495c26.508,7.206,55.868,3.517,80.183-11.96C401.305,163.957,435.313,109.894,404.237,67.346z   M85.765,374.903c-4.16,7.896-13.342,12.815-21.198,16.355c-4.47,2.012-8.679,2.785-12.966,3.15  c-11.901-5.22-22.474-12.365-22.965-27.771c-0.561-17.62,16.044-33.502,33.577-30.707C79.256,338.645,94.889,357.583,85.765,374.903  z" />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

export default Spanner
